import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useHeaderStyle from '~styles/useHeaderStyle';
import TestimonialCarousel from '~components/TestimonialCarousel';
import Box from '@material-ui/core/Box';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '105px',
    paddingBottom: '126px',
    gap: 60,
    [theme.breakpoints.down('xs')]: {
      gap: 15,
      paddingTop: '45px',
      paddingBottom: '75px'
    }
  },
  section: {
    position: 'relative',
    backgroundColor: '#FFFFFF'
  }
}));

const Testimonials = ({ title, testimonials, bottom_shape_divider }) => {
  const classes = useStyles();
  const headerStyle = useHeaderStyle();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <Typography variant="h3" className={headerStyle.h2}>
          {title}
        </Typography>
        <Box component="div" width="100%">
          <TestimonialCarousel testimonials={testimonials} />
        </Box>
      </div>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

Testimonials.propTypes = {
  title: PropTypes.string,
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }),
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      testimonial: PropTypes.string,
      author: PropTypes.string,
      designation: PropTypes.string
    })
  )
};

Testimonials.defaultProps = {
  title: 'AlephBeta Stories',
  bottom_shape_divider: null,
  testimonials: []
};

export default Testimonials;
